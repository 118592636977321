html,
body {
    height: 100%;
}

.wrap {
    min-height: 100%;
    height: auto;
    margin: 0 auto -60px;
    padding: 0 0 60px;
}

.wrap > .container {
    padding: 70px 15px 20px;
}

.footer {
    //height: 60px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    padding-top: 20px;
}

//https://stackoverflow.com/questions/16214326/bootstrap-dropdown-with-hover
@media (min-width: 768px) {
    #main_nav_bar {
        .dropdown:hover {
            .dropdown-menu {
                display: block;
                margin-top: 0; // remove the gap so it doesn't close
            }
        }
    }
}

#task_queue_panel {
    background-color: white;
    padding: 20px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    font-size: 14px;
    position: fixed;
    top: 100px;
    right: -550px;
    width: 550px;
    z-index: 500;

    &.active {
        right: 0;
    }

    .panel-control {
        position: absolute;
        top: 34px;
        left: -65px;
        width: 100px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #f5f5f5;
        border-radius: 5px 5px 0 0;
        border: 1px solid #ddd;
        transform: rotate(-90deg);
        box-shadow: -1px -1px 5px #888;
        color: #777;
        cursor: pointer;

        &:hover {
            color: #333;
        }
    }

    .panel-group {
        max-height: 60vh;
        overflow-y: auto;
    }

    .panel-heading {
        padding: 5px 10px;
    }

    .panel-title {
        font-size: 14px;
    }

    h2 {
        margin: 0 0 15px 0;
    }
}

.jumbotron {
    text-align: center;
    background-color: transparent;
}

.jumbotron .btn {
    font-size: 21px;
    padding: 14px 24px;
}

.not-set {
    color: #c55;
    font-style: italic;
}

/* add sorting icons to gridview sort links */
a.asc:after, a.desc:after {
    position: relative;
    top: 1px;
    display: inline-block;
    //noinspection CssNoGenericFontName
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    padding-left: 5px;
}

a.asc:after {
    content: /*"\e113"*/ "\e151";
}

a.desc:after {
    content: /*"\e114"*/ "\e152";
}

.sort-numerical a.asc:after {
    content: "\e153";
}

.sort-numerical a.desc:after {
    content: "\e154";
}

.sort-ordinal a.asc:after {
    content: "\e155";
}

.sort-ordinal a.desc:after {
    content: "\e156";
}

.grid-view {
    .filters {
        input,
        select {
            min-width: 40px;
        }

        .form-control {
            height: auto;
            padding: 2px 4px;
        }
    }

    .action-column {
        text-align: right;
        white-space: nowrap;

        a {
            color: #000000;

            &.delete {
                color: darkred;
            }
        }
    }
}

a {
    &.delete {
        color: darkred;
    }
}

.panel-default > .panel-heading .badge-primary,
.badge-primary {
    background-color: #337ab7;
}

.panel-default > .panel-heading .badge-success,
.badge-success {
    background-color: #5cb85c;
}

.panel-default > .panel-heading .badge-info,
.badge-info {
    background-color: #5bc0de;
}

.panel-default > .panel-heading .badge-warning,
.badge-warning {
    background-color: #f0ad4e;
}

.panel-default > .panel-heading .badge-danger,
.panel-default > .panel-heading .badge-important,
.badge-danger,
.badge-important {
    background-color: #d9534f;
}

.hint-block {
    display: block;
    margin-top: 5px;
    color: #999;
}

.error-summary {
    color: #a94442;
    background: #fdf7f7;
    border-left: 3px solid #eed3d7;
    padding: 10px 20px;
    margin: 0 0 15px 0;
}

/* align the logout "link" (button in form) of the navbar */
.nav li > form > button.logout {
    padding: 9px 15px;
    border: none;
}

@media (max-width: 767px) {
    .nav li > form > button.logout {
        display: block;
        text-align: left;
        width: 100%;
        padding: 10px 15px;
    }
}

.nav > li > form > button.logout:focus,
.nav > li > form > button.logout:hover {
    text-decoration: none;
}

.nav > li > form > button.logout:focus {
    outline: none;
}

.btn {
    white-space: normal;
}

.list-group.list-group-root {
    padding: 0;
    overflow: hidden;
}

.list-group.list-group-root .list-group {
    margin-bottom: 0;
}

.list-group.list-group-root .list-group-item {
    border-radius: 0;
    border-width: 1px 0 0 0;
}

.list-group.list-group-root > .list-group-item:first-child {
    border-top-width: 0;
}

.list-group.list-group-root > .list-group > .list-group-item {
    padding-left: 30px;
}

.list-group.list-group-root > .list-group > .list-group > .list-group-item {
    padding-left: 45px;
}

.list-group {
    .list-group-item {
        width: 100%;
        overflow: hidden;
    }
}

// Custom

body {
    padding-top: 40px;
    font-size: 13px;
    line-height: 16px;
}

.sidebar-nav {
    padding: 9px 0;
}

.dropdown-menu {
    form {
        padding: 0;

        button.btn-link {
            display: block;
            width: 100%;
            color: #333;
            font-weight: normal;
            text-decoration: none;
            padding: 3px 20px;
            text-align: left;
            border: none;

            &:hover,
            &:focus {
                color: #262626;
                background-color: #f5f5f5
            }
        }
    }
}

h1 {
    margin-bottom: 9px;
}

.breadcrumb {
    margin: 0;
}

.main-flash-area {
    .alert {
        margin: 0;
        border-radius: 0;
    }
}

.table a.act {
    color: gray;
}

.table a.act:hover {
    color: black;
}

.order .table td,
.order .table th {
    padding: 5px;
}

.table {
    // Cells
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                padding: 4px 5px;
            }
        }
    }

    .sent {
        td {
            color: green;
        }
    }

    .notified {
        td {
            color: blue;
        }
    }

    .need_check {
        td {
            color: red;
        }
    }

    .old {
        td {
            color: #316ac5;
        }
    }

    .h {
        td {
            color: lightgray;
        }

        &.error {
            td {
                color: darkred;
            }
        }
    }

    .defect {
        td {
            text-decoration: line-through;
        }
    }

    tr {
        &.set {
            font-weight: bold;
        }
    }

    td {
        .del {
            float: right;
        }

        .not_edit,
        .not_edit a {
            color: lightgray;
        }

        &.pl del,
        &.pl del a {
            color: lightcoral;
        }

        &.f {
            .status {
                font-size: 0.9em;
                padding: 0.5em;
                margin: -5px -5px 10px -5px;
                overflow: hidden;
                white-space: nowrap;
                background-color: #5e93c5;
                color: white;
            }
        }

        .empty {
            color: red;
        }

        .not-all {
            color: darkgreen;
        }

        .full {
            color: green;
        }
    }

    .own {
        td {
            border-top: 3px solid orange;
            border-bottom: 3px solid orange;

            &.f {
                border-left: 3px solid orange;

                .status {
                    background-color: orange;
                    color: white;
                }
            }

            &.l {
                border-right: 3px solid orange;
            }
        }
    }

    .own-other td,
    .disable td,
    .disable td .empty,
    .not_edit td .empty {
        color: gray;
    }

    .disable {
        td {
            text-decoration: line-through;
            color: lightcoral;
        }
    }

    .not_edit {
        td {
            color: lightgray;
            background-color: white;
        }
    }

    .not_edit {
        td {
            img {
                opacity: 0.4;
            }
        }
    }

    .own-other {
        td {
            &.f {
                .status {
                    background-color: lightgray;
                    color: black;
                }
            }
        }
    }

    .s_del {
        td {
            border-top: 1px solid lightcoral;
            border-bottom: 1px solid lightcoral;

            &.f {
                border-left: 1px solid lightcoral;

                .status {
                    background-color: lightcoral;
                    color: white;
                }
            }

            &.l {
                border-right: 1px solid lightcoral;
            }
        }
    }

    .s_close {
        td {
            border-top: 1px solid green;
            border-bottom: 1px solid green;

            &.f {
                border-left: 1px solid green;

                .status {
                    background-color: green;
                    color: white;
                }
            }

            &.l {
                border-right: 1px solid green;
            }
        }
    }

    .img-responsive {
        min-width: 35px;
    }
}

dl {
    &.dl-compact {
        dd {
            margin-bottom: 5px;
            padding-left: 20px;
        }
    }
}

#black_list_info {
    p {
        margin-bottom: 0.7em;
    }

    li {
        background-color: greenyellow;
        border: 1px solid white;
        padding: 3px;

        &.black_in {
            background-color: red;

            a {
                color: #000000;
            }
        }
    }
}

.jeditable,
.jeditable_select {
    cursor: text;
    padding: 3px;
    white-space: nowrap;
}

.jeditable:hover,
.jeditable_select:hover,
.jeditable_text:hover {
    background-color: yellow;
}

.jeditable_select {
    .great {
        background-color: lightgreen;
    }

    .warn {
        color: darkred;
    }
}

span.jeditable_text {
    background-color: lightgoldenrodyellow;
}

span.jeditable form {
    display: inline;
}

.order {
    .distributor-contacts {
        p {
            display: inline-block;
            font-size: 11px;
            margin: 0 10px 0 0;
        }
    }
}

.order_edit {
    #sidebar {
        h3 {
            font-size: 16px;
            line-height: normal;
        }

        .user-info,
        .address-info,
        .call-info {
            border: 1px dashed lightgrey;
            padding: 10px;

            h2 {
                font-size: 13px;
                line-height: normal;
                margin-top: 0;
            }

            p {
                margin-bottom: 5px;
            }

            &.danger {
                background-color: #f2dede;
            }

            &.success {
                background-color: #eff8ef;
            }

            .alert {
                margin: 10px 0 0 0;
                padding: 5px;
                border-color: red;
            }
        }

        .call-info {
            .badge {
                font-size: 10px;
            }
        }

        .user-info {
            margin-top: 20px;
        }

        .text-danger {
            color: red;
        }

        .editable-inline {
            width: 100%;

            .form-inline {
                &.editableform {
                    .control-group {
                        width: 100%;

                        .editable-input {
                            width: 100%;

                            .form-control {
                                width: 100%;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .grid-view {
        overflow: auto;
    }

    .user-admin-note {
        font-weight: bold;
        color: darkviolet;
        border: 1px dashed darkviolet;
        padding: 5px;
        margin-bottom: 10px;
    }

    .bill-total {
        border: 1px dashed #d3d3d3;
        padding: 10px;

        table {
            th {
                font-weight: normal;
            }

            th, td {
                padding: 2px 5px;
            }

            .deposit {
                th, td {
                    padding-bottom: 5px;
                }
            }

            .total {
                border-top: 1px solid #d3d3d3;

                th, td {
                    padding-top: 5px;
                }

                th {
                    font-weight: bold;
                }
            }

            .delivery {
                th, td {
                    padding-bottom: 10px;
                }
            }
        }
    }
}

.page-header {
    .page-actions {
        margin-top: 5px;
    }
}

#sidebar {
    .shop-logo {
        margin-bottom: 10px;
        margin-left: auto;
        width: 50%;
    }
}

.order,
.wait-partner {
    .table {
        td {
            .shop-logo {
                width: 50px;
                float: right;
            }
        }
    }
}

.order-create {
    .shop-logo {
        width: 200px;
        float: right;
    }
}

td select,
td input,
td form {
    margin: 0;
}

.form-inline {
    input,
    select,
    .ui-multiselect,
    .checkbox {
        margin: 0 10px 0 5px;
    }
}

.search-form {
    font-size: 12px;

    padding: 8px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;

    label,
    input,
    select,
    button {
        font-size: 12px;
    }

    input,
    select,
    button {
        padding: 2px 7px;
        height: initial;
    }

    select {
        line-height: normal;
        height: auto;
    }

    hr {
        margin: 5px 0;
    }

    &.well {
        padding: 10px;
    }
}

h2 {
    font-size: 20px;
    line-height: 25px;
}

.page-header {
    padding: 0;
    margin: 0 0 10px 0;

    h1 {
        font-size: 20px;
        line-height: 20px;
        margin: 5px 0;

        small {
            font-size: 16px;
        }
    }
}

.page-additional-links {
    margin-right: 10px;

    .admin-favorites-delete {
        color: #fcb116;
    }

    .admin-favorites-add {
        color: #888;
    }

}

img {
    max-width: none;
}

.thumbnails img,
img.resize,
.carousel img {
    max-width: 100%;
}

.thumbnails {
    h3 {
        font-size: 16px;
        line-height: normal;
    }
}

.extruder {
    position: fixed;
    cursor: default;
    //noinspection CssUnknownTarget
    background: url("/js/plugin/jquery.mb.extruder.2.3/elements/extruder_blank.png");

    .header {
        height: 27px;
    }

    .content {
        display: none;
        background: #ffffff;
        padding: 10px 0;
    }

    &.open .content {
        box-shadow: 2px 0 5px #999999;
    }

    .footer {
        display: none;
        height: 10px;
        background: #000000;
        border-radius: 0 0 8px 8px;
        box-shadow: 2px 0 5px #999999;
    }

    &.top {
        .flap {
            color: #ffffff;
            font: 18px/28px Arial, Helvetica, sans-serif;
            text-align: center;
            display: block;
            margin: auto;
            padding: 0 5px 5px 5px;
            height: 30px;
            width: 180px;
            background: #000000;
            cursor: pointer;
            border-radius: 0 0 8px 8px;
            box-shadow: 2px 0 5px #999999;
            text-shadow: 2px 2px 2px #333333;
        }

        .content {
            border-radius: 0 0 8px 8px;
        }
    }

    &.bottom {
        .flap {
            position: relative;
            color: white;
            font: 18px/28px Arial, Helvetica, sans-serif;
            text-align: center;
            display: block;
            margin: 1px auto auto;
            padding: 0 5px 5px 5px;
            height: 30px;
            width: 180px;
            background: #000000;
            cursor: pointer;
            border-radius: 8px 8px 0 0;
            text-shadow: 2px 2px 2px #333333;
            box-shadow: 2px 0 5px #999999;
        }

        .footer {
            border-radius: 8px 8px 0 0;
            position: relative;
            margin-top: -5px;
        }

        .content {
            border-radius: 8px 8px 0 0;
        }

        .ext_wrapper {
            bottom: 0;
        }
    }

    &.left {
        height: 100%;
        //noinspection CssUnknownTarget
        background: url("/js/plugin/jquery.mb.extruder.2.3/elements/extruder_blank.png");

        &.open {
            .content {
                box-shadow: 2px 0 5px #999999;
            }
        }

        .content {
            border-right: 3px solid #000000;
        }

        .ext_wrapper {
            height: 100%;
        }

        .footer {
            display: none;
        }

        .flap {
            font-size: 18px;
            color: white;
            top: 0;
            padding: 10px 0 10px 10px;
            margin-right: -37px;
            background: #000000;
            width: 30px;
            position: absolute;
            right: 0;
            border-radius: 0 8px 8px 0;
            box-shadow: 2px 0 5px #999999;

            .flapLabel {
                background: #000000;
            }
        }
    }

    &.right {
        height: 100%;
        //noinspection CssUnknownTarget
        background: url("/js/plugin/jquery.mb.extruder.2.3/elements/extruder_blank.png");

        .content {
            border-left: 3px solid #000000;
        }

        &.open {
            .content {
                box-shadow: -2px 0 5px #999999;
            }
        }

        .ext_wrapper {
            height: 100%;
            right: 0;
        }

        .footer {
            display: none;
        }

        .flap {
            font-size: 18px;
            color: white;
            top: 0;
            padding: 10px 0 10px 10px;
            background: #000000;
            width: 30px;
            position: absolute;
            left: -37px;
            border-radius: 8px 0 0 8px;
            box-shadow: -2px 0 5px #999999;

            .flapLabel {
                background: #000000;
            }
        }
    }

    .settingsBtn {
        display: block;
        position: absolute;
        width: 36px;
        height: 36px;
        //noinspection CssUnknownTarget
        background: url("/js/plugin/jquery.mb.extruder.2.3/elements/settingsBtn.png") no-repeat bottom;
        cursor: pointer;
        right: -3px;
        top: -4px;
    }

    .optionsPanel {
        //noinspection CssUnknownTarget
        background: url("/js/plugin/jquery.mb.extruder.2.3/elements/fuzz.gif");
        display: none;
        border-bottom: 1px solid #333333;

        .panelVoice {
            a {
                text-decoration: none;
                display: block;
                color: #cccccc;
                padding: 8px 8px 8px 20px;
                font-size: 16px;
                text-shadow: 2px 2px 2px #333333;
                border-bottom: 1px solid #000000;
                cursor: pointer;

                &:hover {
                    color: #fff;
                    //noinspection CssUnknownTarget
                    background: url("/js/plugin/jquery.mb.extruder.2.3/elements/red_op_50.png");
                }
            }
        }
    }
}

:focus {
    outline: 0;
}

.wait-partner,
.partner {
    .act_sent,
    .act_notify,
    .act_check,
    .act_received {
        white-space: nowrap;
        margin-bottom: 3px;

        &.not-checked {
            svg {
                color: transparent;
            }
        }
    }

    .act_received {
        color: #000000;
        text-shadow: none;
    }
}

.table {
    tbody {
        tr {
            &.received {
                td {
                    background-color: orange;
                }
            }
        }
    }
}

.orders-create {
    .controls {
        position: relative;
    }

    .dropdown-tips {
        background: none repeat scroll 0 0 #FFFFFF;
        border: 1px solid #aaa;
        cursor: pointer;
        display: none;
        list-style: none outside none;
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
        position: absolute;
        top: 25px;
        z-index: 20;
        width: auto;
        margin: 0;

        li {
            background: #fff;
            border-left: 1px solid #FFF;
            border-right: 1px solid #FFF;
            border-top: 1px solid #FFF;
            border-bottom: 1px solid #E8E3D7;
            margin: 0 10px 0 5px;
            padding: 0;
            width: 100%;

            &:hover {
                background: #E8E3D7;
                border: 1px solid #E8E3D7;
            }
        }
    }
}

.list-group-item,
.table {
    .progress {
        margin-bottom: 0;
    }
}

.table {
    tr:hover td,
    tr:nth-child(odd):hover td {
        background-color: #e6e6e6;
    }
}

#statePanel {
    .container {
        height: 100%;
        overflow: auto;
    }

    .panel-body {
        .blur {
            opacity: 0.3;
        }
    }
}

label {
    font-weight: bold;
}

.distributor {
    #DistributorEditForm {
        .help-block {
            margin: -5px 0 10px 18px;
        }
    }

    .table {
        font-size: 8pt;

        th,
        td {
            padding: 4px;
        }
    }
}

body {
    .ui-widget {
        font-size: 0.9em;
    }
}

.block {
    border: 2px solid #E9E2EE;
    background-color: #f4f0f6;
    margin-bottom: 1em;
    padding: 1em;
}

.add {
    margin-top: 20px;
}

.feedbacks {
    #FeedbackAdminEditForm {
        div {
            &.input {
                display: block;
                margin-bottom: 5px;
                margin-top: 10px;
            }
        }
    }
}

.text {
    label {
        display: block;
    }
}

.feedbacks #FeedbackAdminEditForm .text input,
.news .text input {
    width: 100%;
}

#FeedbackProductId,
.feedbacks #ProductArtLocal {
    width: 100px;
}

.comments {
    color: #504E4E;
    font-size: 12px;
}

.comments dl,
.tia dl {
    border: 1px solid #b2b2b2;
    margin-bottom: 15px;
    padding: 10px;
    position: relative;
}

.tia dl.i {
    background-color: #f8f8f8;
}

.comments dt,
.tia dt {
    width: 100%;
    overflow: hidden;
}

.comments dt em,
.tia dt em {
    float: right;
    padding: 2px 5px;
    font-size: 0.7em;
}

.comments dt cite,
.tia dt cite {
    float: left;
}

.comments dd,
.tia dd {
    padding-top: 10px;
    margin-left: 0;
    width: 100%;
    overflow: hidden;
}

.comments .ans,
.tia .ans {
    margin-top: 15px;
    font-style: italic;
    width: 100%;
    overflow: hidden;
}

.comments {
    .ans {
        cite {
            color: black;
            float: left;
        }
    }

    .a {
        margin-left: 145px;
    }

    .resend {
        text-align: right;
        font-weight: normal;
        margin-top: 10px;

        b {
            font-weight: bold;
        }
    }

    .prv {
        width: 90px;
        float: left;
        margin-right: 20px;

        .brand {
            margin: 5px 0;
        }
    }

    .cont {
        margin-left: 110px;
    }

    dl.own {
        border: 2px solid #E87830;
    }

    .section {
        padding: 10px;
        margin-top: 10px;
        border: 1px solid lightgrey;

        .label {
            float: right;
        }
    }
}

.modal-header {
    h2 {
        margin: 0;
    }
}

.tia .a {
    margin-top: 10px;
}

.tia .s-1,
.tia .s-2,
.tia .s-3,
.tia .s-4,
.tia .status {
    border: 3px solid white;
}

.tia .s-1,
.tia .s-1 .status {
    border-color: orange;
}

.tia .s-1 .status,
.order .tickets .s-1 {
    background-color: orange;
}

.tia .s-2,
.tia .s-2 .status {
    border-color: green;
}

.tia .s-2 .status,
.order .tickets .s-2 {
    background-color: green;
}

.tia .s-3,
.tia .s-3 .status {
    border-color: #cd0a0a;
}

.tia .s-3 .status,
.order .tickets .s-3 {
    background-color: #cd0a0a;
}

.tia .s-4,
.tia .s-4 .status {
    border-color: gray;
}

.tia .s-4 .status,
.order .tickets .s-4 {
    background-color: gray;
}

.tia {
    .s-4 {
        color: gray;
    }

    .status {
        position: absolute;
        top: -3px;
        left: 50%;
        padding: 5px;
        color: white;
        font-size: 11px;
    }
}

.order {
    .tickets {
        width: 100%;
        overflow: hidden;
        margin-top: 5px;

        p {
            display: none;
        }

        div {
            text-indent: -10000px;
            width: 10px;
            height: 10px;
            background-color: blue;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            margin-right: 2px;
            float: left;
            border: 1px solid black;
        }
    }
}

.comments {
    margin-bottom: 10px;

    .q {
        //noinspection CssUnknownTarget
        background-image: url('/img/q.png');
        background-position: right top;
        background-repeat: no-repeat;
    }

    .nr {
        font-weight: bold;
    }

    dl.op {
        background-color: #deeff5;
        border: 2px solid #316ac5;
    }
}

#TicketAnswer {
    display: none;
    width: 700px;
    margin: 0;
}

.tia dl:hover .actions #TicketAnswer {
    display: inline;
}

.actions {
    text-align: right;
    width: 100%;
    overflow: hidden;

    form {
        margin: 5px 0;
    }
}

.product {
    .text {
        input {
            width: 400px;
        }
    }
}

#easyTooltip {
    padding: 8px;
    background: #fcfcfc;
    border: 1px solid #e1e1e1;

    p {
        margin: 0 0 .5em 0;
    }

    img {
        background: #fff;
        padding: 1px;
        border: 1px solid #e1e1e1;
    }
}

.can_edit {
    color: #5a5a5a;
}

.info {
    background-color: #fffeef;
    border-top: 1px dashed #ffce24;
    border-bottom: 1px dashed #ffce24;
    padding: 15px;
    font-size: 12px;
    color: black;
}

.back {
    a {
        display: block;
        padding: 15px 15px 25px 15px;
        border-top: 1px solid #c8c8c8;
        background-color: #fbfbfb;
        color: gray;
        font-weight: bold;
        text-decoration: none;

        position: fixed;
        z-index: 1000;
        bottom: 0;
        left: 0;
        width: 50%;
        box-shadow: 0 1px 5px gray;

        &:hover {
            color: black;
            text-shadow: 1px 1px 10px #6c6c6c;
        }
    }
}

.table .uc,
.order #sidebar .uc {
    padding: 5px;
    border: 1px dashed lightsteelblue;
    color: black;
}

.order .table .ac,
.order #sidebar .ac,
.user #sidebar .ac,
.order #sidebar .aan,
.order #sidebar .al,
.callbacks .items .admin_note {
    margin-top: 10px;
    padding: 5px;
    border: 1px dashed #cd0a0a;
}

.dropdown.text-templates.form-control {
    width: auto;
    padding: 0;
    border: none;

    .text-template-button {
        margin-top: 5px;
        width: 100%;
    }
}

.order {
    #sidebar {
        .aan {
            border-color: green;
        }

        .al {
            border-color: blue;
        }
    }

    h2 a {
        &.a {
            display: inline-block;
            margin-left: 1em;
            font-size: 0.5em;
            color: white;
            background-color: red;
            padding: 0.5em;
            text-decoration: none;

            &:hover {
                background-color: lightcoral;
            }
        }
    }
}

.grid-view {
    .manual-togle {
        display: block;
        margin: 5px 0;
        cursor: pointer;
    }

    .manual {
        display: none;
    }
}

.logs {
    margin-top: 3em;
    font-size: 0.9em;

    cite {
        padding: 0;
        margin: 0 0 0.8em 0;
        display: block;
        font-weight: normal;
        font-style: normal;
    }

    dl {
        border: 1px solid gray;
        padding: 1em;

        &.p-5 {
            background-color: pink;
            border-color: red;
        }
    }

    dd {
        margin: 0;
    }
}

.logs .more,
#history .more {
    cursor: pointer;
    font-size: 0.8em;
    color: darkblue;
}

.site {
    .notify {
        margin-top: 2em;
        font-size: 0.9em;
        padding: 10px;
        background-color: #fffcdc;

        .desc {
            padding: 10px;
        }

        h2 {
            text-shadow: none;
            padding: 0;
            color: gray;
        }

        p {
            margin: 0;
        }
    }
}

.site .date,
.uc .date,
.ac .date {
    font-style: italic;
    color: gray;
}

#history {
    margin: 20px 0;
}

.pack-item {
    border: 2px solid black;
    padding: 0.7em;
    margin-bottom: 1.5em;

    h3 {
        margin-top: 0;
    }

    .date {
        float: right;
        font-style: italic;
    }

    .op {
        color: darkblue;
        margin-top: 0.5em;
        font-size: 1.2em;
    }

    .sa {
        text-align: right;
    }

    &.wait-from-storage {
        background-color: #fdf7ed;
        opacity: 0.9;
        border: dashed #f0ad4e;
    }

    &.not-validate {
        border-color: #d9534f;
        background-color: #fffbfc;
    }

    &.validate {
        border-color: #5cb85c;
        background-color: #eff8ef;
    }

    .table {
        background-color: white;
    }
}

.validate-pack-form {
    h2 {
        margin: 0 0 10px 0;
    }
}

.find_res {
    background-color: greenyellow;
}

b.black_in {
    background-color: red;

    a {
        color: white;
        padding: 0.5em;
        text-decoration: none;
    }
}

tr.black td,
.table-striped tbody tr.black td {
    background-color: black;
    color: white;
}

tr.black td a,
tr.i.black td a {
    color: lightblue;
}

tr.grey td,
.table-striped tbody tr.grey td {
    background-color: grey;
    color: white;
}

tr.grey td a,
tr.i.grey td a {
    color: lightblue;
}

tr.green td,
tr.i.green td {
    background-color: lightgreen;
}

tr.red td,
tr.i.red td {
    background-color: lightcoral;
}

.pl {
    font-size: 0.9em;
}

.pl .reg {
    border: 1px solid darkgoldenrod;
    margin-bottom: 0.4em;
    padding: 0.3em;
}

.pl .reg.not_edit,
.order_edit .reg.not_edit {
    border-color: lightgray;
}

.order_edit {
    .reg {
        border: 2px solid darkgoldenrod;
        margin-bottom: 0.4em;

        .table {
            margin: 0;
        }

        h3 {
            display: block;
            background-color: darkgoldenrod;
            margin: 0;
            color: white;
            font-size: 18px;
        }

        .content {
            margin: 5px;
        }

        .prepay-detail {
            margin-top: 10px;
        }
    }
}

.pl .reg .t {
    display: block;
    background-color: darkgoldenrod;
    margin-top: -0.3em;
    margin-left: -0.3em;
    margin-right: -0.3em;
    color: white;
}

.order_edit .reg h3 {
    padding: 0.2em;
}

.pl .reg.not_edit .t,
.order_edit .reg.not_edit h3 {
    background-color: lightgray;
}

.order_edit.modal-open {
    overflow-y: scroll;
}

.reg .prg {
    margin-left: -0.3em;
    margin-right: -0.3em;
    background-color: lightpink;
    margin-bottom: 0.3em;
}

.reg .prg div {
    height: 5px;
    background-color: lightgreen;
}

.order_edit .reg h3 span {
    font-weight: normal;
}

.jquery-ajax-loader {
    //noinspection CssUnknownTarget
    background: white url('/img/jquery.ajaxLoader.gif') no-repeat 50% 50%;
    opacity: .6;
    border: 2px dashed black;
}

b.tip {
    cursor: help;
    background-color: #795677;
    color: white;
    display: inline-block;
    font-size: 10px;
    padding: 2px 3px;
    text-shadow: none;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.report {
    border: 1px solid #d3d3d3;
    background-color: #ffffff;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 10px;

    h2 {
        margin: 0 0 10px 0;
        padding: 0;
        font-size: 1em;
    }

    h3 {
        font-size: 0.8em;
    }

    .table {
        background-color: white;
    }
}

#properties {
    dl {
        margin: 5px;
        padding: 10px;
    }

    dt {
        margin-bottom: 10px;
        font-weight: bold;
    }
}

ul.dropdown a {
    text-decoration: none;
}

ul.dropdown,
ul.dropdown li {
    list-style: none;
}

ul.dropdown,
ul.dropdown ul {
    margin: 0;
    padding: 0;
}

.callbacks {
    .items {
        font-size: 12px;

        .status {
            background-color: #5E93C5;
            color: white;
            padding: 5px 10px;
            margin-bottom: 10px;

            span {
                opacity: .3;
            }
        }

        dl {
            border: 1px solid #5E93C5;
            margin-bottom: 15px;
            padding: 0;
            position: relative;

            &.s-2 {
                border-color: lightgray;
            }
        }

        dt {
            width: 100%;
            overflow: hidden;

            em {
                color: white;
                position: absolute;
                top: 5px;
                right: 10px;
                font-size: 0.8em;
                font-style: normal;
            }

            cite {
                padding: 0 10px;
            }
        }

        dd {
            padding: 5px 10px 10px 10px;
            margin: 0;
        }

        .ph {
            margin-bottom: 10px;
        }

        .s-2 {
            .status {
                background-color: lightgray;
                color: black;
            }

            dt em {
                color: black;
            }
        }

        .admin_note {
            margin-bottom: 5px;
        }
    }
}

div.jGrowl b.bad {
    color: red;
    font-size: 15px;
}

a.ajax-qtip:hover {
    background-color: yellow;
    color: black;
}

html table.tablesorter thead tr th {
    padding-right: 21px;
}

html table.tablesorter tbody tr:hover td {
    background-color: #cdcde9;
}

/*
	LEVEL ONE
*/
ul.dropdown {
    position: relative;
}

ul.dropdown li {
    font-weight: bold;
    float: left;
    zoom: 1;
    background: #ccc;
    list-style: none;
}

ul.dropdown a:hover {
    color: #000;
}

ul.dropdown a:active {
    color: #ffa500;
}

ul.dropdown li a {
    display: block;
    padding: 4px 8px;
    border-right: 1px solid #333;
    color: #222;
}

ul.dropdown li:last-child a {
    border-right: none;
}

/* Doesn't work in IE */
ul.dropdown li.hover,
ul.dropdown li:hover {
    background: #F3D673;
    color: black;
    position: relative;
}

ul.dropdown li.hover a {
    color: black;
}

button#edit-adress {
    margin-bottom: 20px;
    margin-left: 30px;
}

/*
	LEVEL TWO
*/
ul.dropdown ul {
    width: 220px;
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 1px 5px gray;
}

ul.dropdown ul li {
    font-weight: normal;
    background: #f6f6f6;
    color: #000;
    border-bottom: 1px solid #ccc;
    float: none;
}

/* IE 6 & 7 Needs Inline Block */
ul.dropdown ul li a {
    border-right: none;
    width: 100%;
    display: inline-block;
}

/*
	LEVEL THREE
*/
ul.dropdown ul ul {
    left: 100%;
    top: 0;
    width: 650px;
}

ul.dropdown li:hover > ul {
    visibility: visible;
}

.ui-multiselect {
    padding: 2px 0 2px 4px;
    text-align: left
}

.ui-multiselect span.ui-icon {
    float: right
}

.ui-multiselect-single input {
    position: absolute !important;
    top: auto !important;
    left: -9999px;
}

.ui-multiselect-single label {
    padding: 5px !important
}

.ui-multiselect-header {
    margin-bottom: 3px;
    padding: 3px 0 3px 4px
}

.ui-multiselect-header ul {
    font-size: 0.9em
}

.ui-multiselect-header ul li {
    float: left;
    padding: 0 10px 0 0
}

.ui-multiselect-header a {
    text-decoration: none
}

.ui-multiselect-header a:hover {
    text-decoration: underline
}

.ui-multiselect-header span.ui-icon {
    float: left
}

.ui-multiselect-header li.ui-multiselect-close {
    float: right;
    text-align: right;
    padding-right: 0
}

.ui-multiselect-menu {
    display: none;
    padding: 3px;
    position: absolute;
    z-index: 10000
}

.ui-multiselect-checkboxes {
    position: relative /* fixes bug in IE6/7 */;
    overflow-y: scroll
}

.ui-multiselect-checkboxes label, #search_form .ui-multiselect-checkboxes label {
    cursor: default;
    display: block;
    border: 1px solid transparent;
    padding: 3px 1px;
}

.ui-multiselect-checkboxes label input {
    position: relative;
    top: 1px
}

.ui-multiselect-checkboxes li {
    clear: both;
    font-size: 1em;
    padding-right: 3px
}

.ui-multiselect-checkboxes li.ui-multiselect-optgroup-label {
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid
}

.ui-multiselect-checkboxes li.ui-multiselect-optgroup-label a {
    display: block;
    padding: 3px;
    margin: 1px 0;
    text-decoration: none
}

/* remove label borders in IE6 because IE6 does not support transparency */
* html .ui-multiselect-checkboxes label {
    border: none
}

.ui-multiselect-hasfilter ul {
    position: relative;
    top: 2px
}

.ui-multiselect-filter {
    float: left;
    margin-right: 10px;
    font-size: 11px
}

.ui-multiselect-filter input {
    width: 100px;
    font-size: 10px;
    margin-left: 5px;
    height: 15px;
    padding: 2px;
    border: 1px solid #292929;
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
}

.ui-tooltip-wiki {
    max-width: 440px;
}

.ui-tooltip-wiki .ui-tooltip-content {
    padding: 10px;
    line-height: 12.5px;
}

.ui-tooltip-wiki .note {
    margin-bottom: 0;
    font-style: italic;
    color: #888;
}

.qtip {
    max-width: none;
}

.short-info {
    color: black;

    img {
        padding: 0 10px 0 0;
    }

    .left-content,
    .right-content {
        float: left;
    }

    .right-content {
        max-width: 250px;

        p {
            margin-bottom: 5px;
        }
    }

    .table {
        th,
        td {
            line-height: normal;
        }
    }

    .content {
        max-height: 400px;
        overflow: auto;
    }

    h1 {
        color: black;
        border-style: none;
        margin: 0 0 7px;
        font-size: 1em;
        line-height: normal;
    }

    h2 {
        font-size: 1em;
        background-color: #f1f1f1;
        padding: 0 10px;
        clear: both;
    }

    .price del {
        color: gray;
    }

    .menu {
        clear: both;
        width: 100%;
        max-width: 390px;
        overflow: hidden;
        margin-bottom: -4px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        li,
        li a {
            display: inline-block;
        }

        li a {
            padding: 4px;

            &:hover {
                background-color: blue;
                color: white;
            }
        }
    }

    .shop-logo {
        margin-bottom: 10px;
        width: 80px;
    }
}

.prod_tickets {
    margin-top: 10px;
    border: 1px solid #d3d3d3;
    padding: 5px;

    .error,
    .done {
        margin-bottom: 10px;
    }

    .error {
        color: red;
    }

    .done {
        color: green;
    }
}

.partner {
    .table a {
        &.e {
            color: green;
        }

        &.h {
            color: red;
        }
    }
}

.checklist {
    width: 100%;
    overflow: hidden;

    label.selected {
        color: blue;
    }
}

//Уменьшение высоты меню
.navbar .nav > li > a {
    padding: 7px 15px;
}

.navbar-brand {
    padding: 7px 20px;
    height: auto;
}

.navbar,
.navbar-header {
    min-height: 35px;
    margin: 0;
}

.navbar-toggle {
    padding: 2px 10px;
}

li {
    line-height: 16px;
}

.btn {
    font-size: 13px;
    line-height: 16px;
}

.products-linked-list {
    .product {
        display: inline-block;
        margin: 5px 5px 0 0;
        position: relative;

        &.h {
            opacity: 0.3;
        }

        .delete {
            position: absolute;
            top: 3px;
            right: 3px;
        }
    }

    .actions {
        margin-top: 10px;
        text-align: center;
    }
}

.label {
    white-space: normal;
    font-size: 11.844px;
    text-align: left;
    display: inline-block;
}

.label-default {
    background-color: #999;
}

.re-ordering-table {
    input {
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .table-striped tbody > tr:nth-child(odd) > td,
    .table-striped tbody > tr:nth-child(odd) > th {
        background-color: #f9f9f9;
    }

    .table-striped tbody > tr:nth-child(even) > td,
    .table-striped tbody > tr:nth-child(even) > th {
        background-color: white;
    }
}

#dialog-warehouse-dlg {
    label {
        display: block;
    }
}

label {
    &.bullet-mark-success:before,
    &.bullet-mark-danger:before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 5px;
    }

    &.bullet-mark-success:before {
        background-color: green;
    }

    &.bullet-mark-danger:before {
        background-color: red;
    }
}

body.fast-edit-props {
    .ajax-form {
        label,
        button {
            display: block;
        }

        button {
            margin-top: 20px;
        }

        input,
        textarea {
            color: black;
            min-width: 400px;
        }

        .translate-button {
            display: inline-block;
        }

        .lang-header {
            h2 {
                margin-top: 10px;
                margin-bottom: 5px;
            }

            button {
                margin-top: 5px;
            }
        }
    }
}

#barcode_form {
    #barcode_input {
        font-size: 30px;
        height: auto;
    }
}

.app-for-collection {
    .item {
        width: 25%;
        padding: 5px;
        float: left;

        .content {
            border: 1px solid darkgray;
            padding: 5px;
            position: relative;

            p {
                margin: 0 0 5px 0;
            }

            .barcode {
                text-align: right;
            }

            .art {
                font-weight: bold;
                text-align: center;
                font-size: 16px;
            }

            .tags {
                margin: 0;
            }

            .amount {
                position: absolute;
                right: 10px;
                bottom: 10px;
                margin: 0;
                font-size: 20px;
            }
        }
    }
}

/* TipTip CSS - Version 1.2 */

#tiptip_holder {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
}

#tiptip_holder.tip_top {
    padding-bottom: 5px;
}

#tiptip_holder.tip_bottom {
    padding-top: 5px;
}

#tiptip_holder.tip_right {
    padding-left: 5px;
}

#tiptip_holder.tip_left {
    padding-right: 5px;
}

#tiptip_content {
    font-size: 11px;
    color: #fff;
    text-shadow: 0 0 2px #000;
    padding: 4px 8px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background-color: rgb(25, 25, 25);
    background-color: rgba(25, 25, 25, 0.92);
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(transparent), to(#000));
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    box-shadow: 0 0 3px #555;
    -webkit-box-shadow: 0 0 3px #555;
    -moz-box-shadow: 0 0 3px #555;
}

#tiptip_arrow, #tiptip_arrow_inner {
    position: absolute;
    border-color: transparent;
    border-style: solid;
    border-width: 6px;
    height: 0;
    width: 0;
}

#tiptip_holder.tip_top #tiptip_arrow {
    border-top-color: #fff;
    border-top-color: rgba(255, 255, 255, 0.35);
}

#tiptip_holder.tip_bottom #tiptip_arrow {
    border-bottom-color: #fff;
    border-bottom-color: rgba(255, 255, 255, 0.35);
}

#tiptip_holder.tip_right #tiptip_arrow {
    border-right-color: #fff;
    border-right-color: rgba(255, 255, 255, 0.35);
}

#tiptip_holder.tip_left #tiptip_arrow {
    border-left-color: #fff;
    border-left-color: rgba(255, 255, 255, 0.35);
}

#tiptip_holder.tip_top #tiptip_arrow_inner {
    margin-top: -7px;
    margin-left: -6px;
    border-top-color: rgb(25, 25, 25);
    border-top-color: rgba(25, 25, 25, 0.92);
}

#tiptip_holder.tip_bottom #tiptip_arrow_inner {
    margin-top: -5px;
    margin-left: -6px;
    border-bottom-color: rgb(25, 25, 25);
    border-bottom-color: rgba(25, 25, 25, 0.92);
}

#tiptip_holder.tip_right #tiptip_arrow_inner {
    margin-top: -6px;
    margin-left: -5px;
    border-right-color: rgb(25, 25, 25);
    border-right-color: rgba(25, 25, 25, 0.92);
}

#tiptip_holder.tip_left #tiptip_arrow_inner {
    margin-top: -6px;
    margin-left: -7px;
    border-left-color: rgb(25, 25, 25);
    border-left-color: rgba(25, 25, 25, 0.92);
}

table.tablesorter tbody tr:nth-child(odd) td {
    background-color: #F0F0F6;
}

.notice-container {
    position: fixed;
    left: 50%;
    top: 10px;
    transform: translateX(-50%);
    width: 300px;
    z-index: 9000;

    .alert {
        text-align: center;
        position: relative;

        button.close {
            float: none;
            position: absolute;
            top: -2px;
            right: 1px;
            outline: none;
            cursor: pointer;
        }
    }
}

.search-active-filter {
    position: relative;

    form {
        display: inline-block;
        margin-right: 60px;
    }

    .filter-field {
        display: inline-block;
    }

    .add-filter-menu-wrapper {
        display: inline-block;
        margin-right: 5px;
        position: relative;
    }

    .search-form-actions {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .save-filter-wrapper {
        display: inline-block;
        margin-right: 5px;

        .save-filter-form {
            position: absolute;
            top: 100%;
            right: 0;
            z-index: 1000;
            display: none;
            float: left;
            min-width: 160px;
            padding: 10px;
            margin: 2px 0 0;
            font-size: 14px;
            text-align: left;
            list-style: none;
            background-color: #fff;
            -webkit-background-clip: padding-box;
            background-clip: padding-box;
            border: 1px solid rgba(0, 0, 0, .15);
            border-radius: 4px;
            box-shadow: 0 6px 12px rgba(0, 0, 0, .175);

            &.active {
                display: block;
            }
        }
    }

    .load-filter-wrapper {
        display: inline-block;
        margin-right: 5px;

        .load-filter-form {
            position: absolute;
            top: 100%;
            right: 0;
            z-index: 1000;
            display: none;
            float: left;
            min-width: 160px;
            padding: 10px;
            margin: 2px 0 0;
            font-size: 14px;
            text-align: left;
            list-style: none;
            background-color: #fff;
            -webkit-background-clip: padding-box;
            background-clip: padding-box;
            border: 1px solid rgba(0, 0, 0, .15);
            border-radius: 4px;
            box-shadow: 0 6px 12px rgba(0, 0, 0, .175);

            .delete-filter-btn {
                float: right;
            }

            &.active {
                display: block;
            }
        }
    }
}

.js-tree-menu {
    margin: 20px 0;
    min-height: 400px;
    border: 1px solid #ccc;
}

/* Webkit Hacks  */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    #tiptip_content {
        padding: 4px 8px 5px 8px;
        background-color: rgba(45, 45, 45, 0.88);
    }

    #tiptip_holder.tip_bottom #tiptip_arrow_inner {
        border-bottom-color: rgba(45, 45, 45, 0.88);
    }

    #tiptip_holder.tip_top #tiptip_arrow_inner {
        border-top-color: rgba(20, 20, 20, 0.92);
    }
}

.menu-save-warning,
.menu-cache-warning {
    display: none;
}

.editableform .form-control.editable-long-input {
    width: 65vw;
}

.city-tips-list {
    position: absolute;
    background: #fff;
    display: block;
    z-index: 3;
    border: 1px solid #ccc;
    list-style: none;
    padding: 0;

    li {
        cursor: pointer;
        padding: 2px 10px;

        &:hover {
            background: #ccc;
        }
    }
}

.css-loader {
    width: 20px;
    height: 20px;
    z-index: 10;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .loader {
        border: 3px solid #f3f3f3;
        border-top: 3px solid #666;
        display: block;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.form-default {
    .form-group {
        margin-right: 0;
        margin-left: 0;
    }

    .control-label {
        margin-bottom: 5px;
    }
}

//Фикс для плагина yii2-widget-colorinput
.input-group-addon,
.input-group-btn {
    width: auto;
}

.text-muted {
    color: lightgray;
}
