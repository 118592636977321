.post-link {
    display: inline-block;
    margin: 0;
}

.btn-link.post-link__button {
    padding: 0;
}

.grid-view {
    .action-column {
        .post-link__button {
            background-color: transparent;
            border: none;
            padding: 0;
        }
    }
}

// Set corners individual because sometimes a single button can be in a .btn-group and we need :first-child and :last-child to both match
.btn-group > .post-link:first-child {
    &:not(:last-child):not(.dropdown-toggle) {
        .btn {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .btn {
        margin-left: 0;
    }
}

// Need .dropdown-toggle since :last-child doesn't apply, given that a .dropdown-menu is used immediately after it
.btn-group > .post-link:last-child:not(:first-child) .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
